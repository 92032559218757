import CommentIcon from "@mui/icons-material/Comment";
import LinearScaleIcon from "@mui/icons-material/LinearScale";
import ListAltIcon from "@mui/icons-material/ListAlt";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import TimelineIcon from "@mui/icons-material/Timeline";
import TitleIcon from "@mui/icons-material/Title";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  timelineItemClasses,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import Typography from "@mui/material/Typography";

import { ChangeHistory } from "../QRE.types";

import useDateFormatter from "@/modules/common/hooks/useDateFormatter";

const timelineIcon = (field: string) => {
  let timeIcon;
  switch (field) {
    case "title": {
      timeIcon = <TitleIcon />;
      break;
    }
    case "status": {
      timeIcon = <LinearScaleIcon />;
      break;
    }
    case "name": {
      timeIcon = <LocalOfferIcon />;
      break;
    }
    case "comments": {
      timeIcon = <CommentIcon />;
      break;
    }
    case "form": {
      timeIcon = <ListAltIcon />;
      break;
    }
    default: {
      timeIcon = <TimelineIcon />;
    }
  }

  return timeIcon;
};

type TimelineProps = {
  changeHistory: ChangeHistory[];
};

const TimeLine = ({ changeHistory }: TimelineProps) => {
  const { datetimeFormatter, DEFAULT_FULL_DATETIME_OPTIONS } =
    useDateFormatter();

  let dayControl = "";
  const datetimeSplitter = (date: string) => {
    const dateString = date.split(" ");
    if (dayControl != dateString[0]) {
      dayControl = dateString[0];
      return date;
    } else {
      return dateString[1];
    }
  };

  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      {changeHistory.length === 0 ? (
        <Typography>No changes made since the creation of the QRE</Typography>
      ) : (
        changeHistory?.map((changedItem, index) => (
          <TimelineItem key={index}>
            <TimelineOppositeContent
              sx={{ m: "auto 0" }}
              align="right"
              variant="body2"
              color="text.secondary"
            >
              {datetimeSplitter(
                datetimeFormatter(
                  new Date(changedItem.created),
                  DEFAULT_FULL_DATETIME_OPTIONS
                )
              )}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot color="primary">
                {timelineIcon(changedItem.field)}
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: "12px", px: 2 }}>
              <Typography variant="h6" component="span">
                {changedItem.creator.name}
              </Typography>
              <Typography>
                modify
                <b> {changedItem.field}</b>
              </Typography>
              {changedItem.field != "form" && (
                <Typography>
                  from<b> {changedItem.previous}</b>
                  <br />
                  to<b> {changedItem.new}</b>
                </Typography>
              )}
            </TimelineContent>
          </TimelineItem>
        ))
      )}
    </Timeline>
  );
};

export default TimeLine;
