import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { dracula, vscodeLight } from "@uiw/codemirror-themes-all";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";

import {
  ActionButton,
  ActionButtonOption,
} from "../../components/ActionButton";
import QRE from "../../QRE";
import { QREDetails } from "../../QRE.types";

import { useAuth } from "@/modules/auth/AuthContext";
import QreEditor from "@/modules/common/components/yamleditor/QreEditor";
import { Option } from "@/modules/common/types/sharedTypes";
import { useThemeModeStore } from "@/theme";

type ConfirmationModal = {
  open: boolean;
  title?: string;
  description?: string;
  handleYes: () => void;
  handleNo: () => void;
  handleClose?: () => void;
};

const QREVersion = () => {
  const params = useParams();
  const id = params.id as string;

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { checkAuthorization } = useAuth();
  const [canUpdate, canCreate] = checkAuthorization([
    "qre::update",
    "qre::create",
  ]);
  const { mode } = useThemeModeStore();

  const { mutateAsync: mutateRestoreForm } = useMutation({
    mutationFn: QRE.edit,
  });

  const { mutateAsync: mutateClone } = useMutation({
    mutationFn: QRE.clone,
  });

  const [modal, setModal] = useState<ConfirmationModal>({
    open: false,
    handleYes: () => {
      return;
    },
    handleNo: () => {
      return;
    },
    handleClose: () => {
      setModal((state) => ({ ...state, open: false }));
    },
  });
  const [version, setVersion] = useState<string>("");

  const qre = queryClient.getQueryData(["qre", { id }]) as QREDetails;

  const { data, isLoading } = useQuery({
    queryKey: ["qre", { id, version }],
    queryFn: () => QRE.getFormByVersion(id, version),
    gcTime: 0,
    throwOnError: true,
    enabled: () => {
      return !!id && !!version;
    },
  });

  const versionOptions: Option[] = useMemo(
    () =>
      (qre?.versions ?? [])
        .sort((a, b) => b.number - a.number)
        .map((v) => ({
          value: v.id,
          label: v.number.toString(),
        })),
    [qre?.versions]
  );

  const actionButtonOptions = [
    canUpdate && {
      value: "restore",
      label: "Restore Version",
    },
    canCreate && { value: "clone", label: "Clone Version" },
  ].filter(Boolean) as ActionButtonOption[];

  const handleActionClick = (value: string) => {
    switch (value) {
      case "clone": {
        setModal((state) => ({
          ...state,
          open: true,
          title: "Clone QRE",
          description: "Are you sure to clone QRE?",
          handleYes: async () => {
            const qre = await mutateClone({ id, version });
            navigate(`/qre/${qre.id}`);
          },
          handleNo: () => {
            setModal((state) => ({ ...state, open: !state.open }));
          },
        }));
        break;
      }
      case "restore": {
        setModal(() => ({
          open: true,
          title: "Confirm restore form",
          description: "Are you sure to restore the form?",
          handleYes: () => {
            mutateRestoreForm({
              id,
              form: data?.form,
            });
            window.location.reload();
          },
          handleNo: () => {
            setModal((state) => ({ ...state, open: !state.open }));
          },
        }));
        break;
      }
    }
  };

  const handleChangeVersion = (event: SelectChangeEvent) => {
    setVersion(event.target.value);
  };

  useEffect(() => {
    if (versionOptions.length === 0) {
      return;
    }
    setVersion(versionOptions?.[0]?.value);
  }, [versionOptions]);

  return (
    <>
      <Stack direction={{ sm: "column", md: "row" }} gap={1}>
        <Paper
          sx={{
            padding: 2,
            width: {
              sm: "100%",
              md: versionOptions.length > 0 ? "50%" : "100%",
            },
          }}
        >
          <Stack gap={2}>
            <Typography mt={1} mb={1}>
              Current Version
            </Typography>
            <QreEditor
              theme={mode === "dark" ? dracula : vscodeLight}
              value={qre?.form}
              disabled
            />
          </Stack>
        </Paper>
        {versionOptions.length > 0 && (
          <Paper sx={{ padding: 2, width: { sm: "100%", md: "50%" } }}>
            <Stack gap={2}>
              <Stack justifyContent="space-between" direction="row">
                <FormControl sx={{ width: 200 }} size="small">
                  <InputLabel id="version-select-label">Version</InputLabel>
                  <Select
                    labelId="version-select-label"
                    value={version}
                    label="Version"
                    onChange={handleChangeVersion}
                  >
                    {versionOptions.map((o) => {
                      return (
                        <MenuItem key={o.value} value={o.value}>
                          <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                          >
                            <Typography>{o.label}</Typography>
                          </Stack>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <ActionButton
                  title="Actions"
                  options={actionButtonOptions}
                  buttonProps={{ size: "small", sx: { lineHeight: 1 } }}
                  menuProps={{ sx: { mt: 1 } }}
                  onClick={handleActionClick}
                />
              </Stack>
              {isLoading ? (
                <Stack justifyContent="center" alignItems="center" my={4}>
                  <CircularProgress />
                </Stack>
              ) : (
                <QreEditor
                  theme={mode === "dark" ? dracula : vscodeLight}
                  value={data?.form}
                  disabled
                />
              )}
            </Stack>
          </Paper>
        )}
      </Stack>
      <Dialog
        open={modal.open}
        keepMounted
        onClose={modal.handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{modal.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {modal.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={modal.handleYes}>
            Yes
          </Button>
          <Button onClick={modal.handleNo}>No</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default QREVersion;
