import TimelineIcon from "@mui/icons-material/Timeline";
import { Stack } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router";

import TimeLine from "../../components/Timeline";
import type { QREChanges } from "../../QRE.types";

import FormSection from "@/modules/common/components/layout/forms/FormSection";

const QREChanges = () => {
  const params = useParams();
  const id = params.id as string;
  const queryClient = useQueryClient();

  const qre = queryClient.getQueryData(["qre", { id }]) as QREChanges;

  return (
    <Stack spacing={4}>
      <FormSection
        icon={TimelineIcon}
        title="QRE Changes"
        description="List of changes to this QRE"
        direction="column"
      >
        <Stack spacing={4}>
          <TimeLine changeHistory={qre.change_history} />
        </Stack>
      </FormSection>
    </Stack>
  );
};

export default QREChanges;
