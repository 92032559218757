import HttpClient from "../common/lib/httpClient";
import { ApiPagination, ApiQuery } from "../common/types/sharedTypes";

import {
  QRECreateParams,
  QREDetails,
  QREEditParams,
  QREList,
} from "./QRE.types";

const getList = async (
  params: ApiPagination & ApiQuery & { creator?: string; status?: string }
): Promise<QREList> => {
  return HttpClient.get(`/qre`, params);
};

const createQRE = async (qre: QRECreateParams): Promise<QREDetails> => {
  return HttpClient.post(`/qre`, qre);
};

const getOne = async (id: string): Promise<QREDetails> => {
  return HttpClient.get(`/qre/${id}`);
};

const edit = async ({ id, ...qre }: QREEditParams): Promise<QREDetails> => {
  return HttpClient.patch(`/qre/${id}`, qre);
};

const clone = async (qre: {
  id: string;
  version: string;
}): Promise<QREDetails> => {
  return HttpClient.post(`/qre/${qre.id}/${qre.version}/clone`, {});
};

const getFormByVersion = (
  id: string,
  versionId: string
): Promise<QREDetails> => {
  return HttpClient.get(`/qre/${id}/${versionId}`);
};

export default {
  getOne,
  getList,
  createQRE,
  edit,
  getFormByVersion,
  clone,
};
