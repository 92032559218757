import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Button, ButtonProps, Menu, MenuItem, MenuProps } from "@mui/material";
import { PropsWithChildren, ReactNode, useState } from "react";

import { Option } from "@/modules/common/types/sharedTypes";

export type ActionButtonOption = Option & {
  disabled?: boolean;
};

type ActionButtonProps = {
  title: ReactNode;
  options: ActionButtonOption[];
  buttonProps?: Omit<ButtonProps, "onClick">;
  menuProps?: Omit<MenuProps, "open">;
  onClick?: (value: string) => void;
};
export const ActionButton = ({
  title,
  options,
  buttonProps,
  menuProps,
  onClick,
}: PropsWithChildren<ActionButtonProps>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickButton = (value: string) => {
    return () => {
      if (onClick) onClick(value);
      handleClose();
    };
  };

  if (options.length === 1) {
    return (
      <Button
        variant="contained"
        disableElevation
        onClick={handleClickButton(options[0].value)}
        {...buttonProps}
        disabled={options[0].disabled}
      >
        {options[0].label}
      </Button>
    );
  }

  return (
    <>
      <Button
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        {...buttonProps}
      >
        {title}
      </Button>
      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        {...menuProps}
      >
        {options.map((o) => (
          <MenuItem
            key={o.value}
            onClick={handleClickButton(o.value)}
            disableRipple
            disabled={o.disabled}
          >
            {o.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
